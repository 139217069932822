// extracted by mini-css-extract-plugin
export var __wab_slot = "PlasmicSwitch-module--__wab_slot--fa8S2";
export var __wab_slotStringWrapper = "PlasmicSwitch-module--__wab_slot-string-wrapper--7OPw+";
export var __wab_text = "PlasmicSwitch-module--__wab_text--jG4d2";
export var labelContainer = "PlasmicSwitch-module--labelContainer--OVIgi";
export var labelContainer___focusVisibleWithin = "PlasmicSwitch-module--labelContainer___focusVisibleWithin--KBdC0";
export var root = "PlasmicSwitch-module--root--1I4wE";
export var root___focusVisibleWithin = "PlasmicSwitch-module--root___focusVisibleWithin--dvBbF";
export var rootisChecked = "PlasmicSwitch-module--rootisChecked--BttsP";
export var slotTargetChildren = "PlasmicSwitch-module--slotTargetChildren--BUjUP";
export var slotTargetChildren___focusVisibleWithin = "PlasmicSwitch-module--slotTargetChildren___focusVisibleWithin--4HxSA";
export var thumb = "PlasmicSwitch-module--thumb--6wI56";
export var thumb___focusVisibleWithin = "PlasmicSwitch-module--thumb___focusVisibleWithin--07G2J";
export var thumbisChecked = "PlasmicSwitch-module--thumbisChecked--rfesl";
export var toggle = "PlasmicSwitch-module--toggle--86N8g";
export var toggle___focusVisibleWithin = "PlasmicSwitch-module--toggle___focusVisibleWithin--weX9P";
export var track = "PlasmicSwitch-module--track--b4zvh";
export var track___focusVisibleWithin = "PlasmicSwitch-module--track___focusVisibleWithin--VK4WA";
export var trackisChecked = "PlasmicSwitch-module--trackisChecked--toJ-V";